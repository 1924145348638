<template>
  <div>
    <b-card-header>
      <b-card-title>
        <h1>
          Dispositivos
        </h1>
      </b-card-title>
    </b-card-header>
    <b-row v-if="isAdmin" class="pt-1">
      <b-col
        md="12"
        class="text-right"
      >
        <b-button
          variant="primary"
          type="button"
          @click="changeTypeImport('add')"
        >
          <FeatherIcon icon="PlusIcon" />
          <span
            class="text-nowrap"
          >
            Adicionar Licenças
          </span>
        </b-button>
                &nbsp;
        <b-button
          variant="secondary"
          type="button"
          @click="changeTypeImport('remove')"
        >
          <FeatherIcon icon="MinusIcon" />
          <span
            class="text-nowrap"
          >
            Remover Licenças
          </span>
        </b-button>
      </b-col>
    </b-row>
    <b-row class="p-1">
      <b-col
        class="p-0 pr-1"
        sm="12"
        md="12"
      >
        <h5>Filtros</h5>
      </b-col>
      <b-col
        md="3"
        class="p-0 pr-1"
      >
        <b-form-input
          v-model="search.search"
          placeholder="Pesquisar por serial ou id"
          name="Serial"
        />
      </b-col>
      <b-col
        md="3"
        class="p-0 pr-1"
      >
        <i2-form-vue-select
          v-model="search.online"
          :options="onlineList"
          placeholder="Dispositivo online"
          name="Serial"
        />
      </b-col>
      <b-col
        md="3"
        class="p-0 pr-1"
      >
        <i2-form-vue-select
          v-model="search.monitoring"
          :options="monitoringList"
          placeholder="Dispositivo em monitoramento"
          name="Serial"
        />
      </b-col>
      <b-col
        sm="6"
        md="2"
      >
        <b-button
          variant="secondary"
          type="button"
          @click="getDevices"
        >
          <FeatherIcon icon="SearchIcon" />
          <span
            class="text-nowrap"
          />
        </b-button>
      </b-col>
    </b-row>
    <Grid
      :fields="fields"
      :items="items"
      edit-button-label="edit"
      new-button-label="new"
      :show-search="false"
      @onChangePage="getDevices"
    >
      <template #cell(serial)="data">
        <u><span class="cursor-pointer" @click="onShowDevideDetails(data.item)">{{ data.item.serial }}</span></u>
        <br>
        <b-badge
          v-if="data.item.is_online"
          pill
          block
          variant="success"
        >
          Máquina ligada - {{ data.item.online_start }}
        </b-badge>
      </template>
      <template #cell(has_license)="data">
        <b-badge
          v-if="data.item.has_license"
          pill
          variant="success"
        >Sim
        </b-badge>
        <b-badge
          v-if="!data.item.has_license"
          pill
          variant="danger"
        >
          Não
        </b-badge>
      </template>
      <template #cell(situation)="data">
        <b-badge
          v-if="data.item.situation ==='provisioned'"
          pill
          block
          variant="success"
        >
          Provisionado
        </b-badge>
        <b-badge
          v-if="data.item.situation === 'unprovisioned'"
          pill
          block
          variant="danger"
        >
          Desprovisionado
        </b-badge>
        <b-badge
          v-if="data.item.in_monitoring"
          pill
          block
          variant="warning"
        >
          Em monitoramento
        </b-badge>

      </template>
      <template
        #cell(action)="data"
        style="width: 10%"
      >
        <div class="px-4 text-center">
          <b-dropdown
            variant="link"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item v-if="!data.item.in_monitoring" @click="addToMonitoring(data.item)">
              <feather-icon icon="CameraIcon" />
              <span class="align-middle ml-50">Monitorar</span>
            </b-dropdown-item>
            <b-dropdown-item v-if="data.item.in_monitoring" @click="removeFromMonitoring(data.item)">
              <feather-icon icon="CameraOffIcon" />
              <span class="align-middle ml-50">Remover do Monitoramento</span>
            </b-dropdown-item>
            <b-dropdown-item v-if="data.item.in_monitoring && data.item.is_online"
                             @click="sendAction(data.item, 'screenshot')"
            >
              <feather-icon icon="CameraIcon" />
              <span class="align-middle ml-50">Solicitar Foto</span>
            </b-dropdown-item>
            <b-dropdown-item v-if="data.item.in_monitoring && data.item.is_online"
                             @click="sendAction(data.item, 'audio')"
            >
              <feather-icon icon="MicIcon" />
              <span class="align-middle ml-50">Solicitar Áudio</span>
            </b-dropdown-item>
            <b-dropdown-item v-if="data.item.is_online"
                             @click="sendAction(data.item, 'location')"
            >
              <feather-icon icon="MapPinIcon" />
              <span class="align-middle ml-50">Solicitar Localização</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </Grid>
    <s-modal
      id="modal1"
      v-model="showModal"
      title="Selecione um arquivo"
      ok-title="Salvar"
      cancel-title="Fechar"
      size="md"
      draggable
      content-class="shadow"
      @ok="importFile"
    >
      <validation-observer ref="fileObserver">
        <b-card-text>
          Selecione o arquivo com dispositivos que você deseja
          <b>{{ typeImport === 'add' ? 'adicionar' : 'remover' }}</b>.
        </b-card-text>
        <i2-form-input-file
          v-model="file"
          required
          label="Arquivo"
          placeholder="Informe aqui um arquivo."
          drop-placeholder="Arraste um arquivo para cá"
          accept="text/csv"
          rules="required"
        />
      </validation-observer>
    </s-modal>
    <device-detail v-model="currentDevice" :show="showDevideDetails" @onClose="onCloseDeviceDetails" />
  </div>

</template>

<script>
import Grid from '@/components/i2/grid/Grid.vue'
import DeviceDetail from '@/components/app/Device/DeviceDetail'
import DeviceDetailMixin from '@/mixins/DeviceDetailMixin'
import SModal from '@/components/i2/container/SModal'
import I2FormInputFile from '@/components/i2/forms/elements/I2FormInputFile.vue'
import { ValidationObserver } from 'vee-validate'
import { getUserData } from '@/auth/utils'

export default {
  components: {

    I2FormInputFile,
    SModal,
    DeviceDetail,
    Grid,
    ValidationObserver,
  },
  mixins: [DeviceDetailMixin],
  data() {
    return {
      isAdmin: false,
      onlineList: [
        { value: 'true', text: 'Online' },
        { value: 'false', text: 'Offline' },
      ],
      monitoringList: [
        { value: 'true', text: 'Em monitoramento' },
        { value: 'false', text: 'Sem monitoramento' },
      ],
      currentDevice: '',
      search: {
        search: '',
        company_code: '',
        online: null,
        monitoring: null,
      },
      company_status: [
        { title: 'Ativa', value: 1 },
        { title: 'Inativa', value: 2 },
      ],
      fields: [
        {
          key: 'serial',
          label: 'Serial',
          class: 'w-50',
          thClass: 'text-center',
        },
        {
          key: 'has_license',
          label: 'Licenciado',
          class: 'w-10',
          thClass: 'text-center',
        },
        {
          key: 'organization.name',
          label: 'Local',
          class: 'w-20',
          thClass: 'text-center',
        },

        {
          key: 'situation',
          label: 'Situação',
          class: 'w-15',
          thClass: 'text-center',
        },
        {
          key: 'action',
          label: 'Acões',
          class: 'w-5',
          thClass: 'text-center',
        },
      ],
      items: { data: [] },
      showModal: false,
      typeImport: null,
      file: null,
      showDevideDetails: false,
      user: getUserData(),
    }

  },
  watch: {},
  created() {
    this.isAdmin = this.user.role === 'super_admin'
  },
  mounted() {
    this.getDevices()
  },
  methods: {
    async getDevices(page) {
      this.items = await this.$search.get(
        'devices',
        page,
        this.search,
        null,
      )
    },
    changeTypeImport(type) {
      this.showModal = true
      this.typeImport = type
    },
    async importFile(evt) {
      evt.preventDefault()
      const isOk = await this.$refs.fileObserver.validate()
      if (!isOk) {
        return
      }
      const formData = new FormData()
      formData.append('file', this.file)
      formData.append('type', this.typeImport)
      this.$http.post('devices', formData).then(() => {
        this.$bvToast.toast('Arquivo importado com sucesso.', {
          title: 'Tudo certo!',
          variant: 'success',
          solid: true,
        })
        this.getDevices(1)
        this.showModal = false
      })
    },
    onShowDevideDetails(device) {
      this.currentDevice = device.uuid
      this.showDevideDetails = true
    },
    onCloseDeviceDetails() {
      this.currentDevice = ''
      this.showDevideDetails = false
    },
    addToMonitoring(device) {

      const response = this.$http.put(`/devices/${device.uuid}/monitoring`)
      if (response.error) {
        this.$notify.error(response.message)
        return
      }

      this.$notify.success('Dispositivo adicionado a lista de monitoramento')
      this.getDevices(1)
    },
    removeFromMonitoring(device) {
      const response = this.$http.delete(`/devices/${device.uuid}/monitoring`)
      if (response.error) {
        this.$notify.error(response.message)
        return
      }
      this.$notify.success('Dispositivo removido da lista de monitoramento')
      this.getDevices(1)
    },
    sendAction(device, action) {
      const response = this.$http.post(`/devices/${device.uuid}/action`, { action })
      if (response.error) {
        this.$notify.error(response.message)
        return
      }
      this.$notify.success('Ação Solicitada ao equipamento')
      this.getDevices(1)
    },

  },
}
</script>
<style>

.filter-input {
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    color: #757575;
    font-size: 13px;
    font-weight: 500;
    width: 100%;
    text-transform: none;
    height: 40px;
    padding: 0 10px !important;
    border-radius: 0;
    background-color: #ebebeb;
    border: none;
}

.filter-input .form-control, vs__dropdown-toggle {
    border: none !important;
    background-color: #ebebeb !important;
}

[dir] .filter-input .reportrange-text {
    padding-top: 10px !important;
}

[dir] .dk-search-label {
    margin-top: 10px;
}

[dir=ltr] .dk-search-label {
    margin-right: 4px;
}

[dir=rtl] .dk-search-label {
    margin-left: 4px;
}

.dk-search-label .custom-control-label {
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    text-transform: uppercase !important;
    color: #757575;
    font-size: 13px;
    font-weight: 500;
    width: 100%;
    text-transform: none;
}

[dir] .dk-search-label .custom-control-label {
    margin-top: 5px;
    border-radius: 0;
    border: none;
}

</style>
